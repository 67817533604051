import Vue from "vue";

const postMessagesTypes = {
  GET_USER_DATA: "getUserData", // returns user data
  CLOSE_IFRAME: "closeIframe", // asks the parent to close the iframe
  LOGOUT: "logout", // asks the parent to close the iframe
};

const urlParams = {
  RETRIEVE_AUTHENTICATED_USER_AND_CLOSE:
    "retrieve_authenticated_user_and_close", // try to get user data and close the iframe after that
  SIGN_IN: "sign_in", // open in sign in page and close iframe after successfull sign in
  GOOGLE_SIGN_IN: "google_sign_in", // open in sign in page and close iframe after successfull sign in
  LOGOUT: "logout",
};

Vue.mixin({
  methods: {
    iframeReturnUserData(profile) {
      window.parent.postMessage(
        {
          type: postMessagesTypes.GET_USER_DATA,
          ...profile,
        },
        "*"
      );
    },
    iframeClose() {
      window.parent.postMessage(
        {
          type: postMessagesTypes.CLOSE_IFRAME,
        },
        "*"
      );
    },
    iframeLogout() {
      window.parent.postMessage(
        {
          type: postMessagesTypes.LOGOUT,
        },
        "*"
      );
    },
    iframeParams() {
      const params = this.$route.query;
      this.$store.commit("SET_PARAMS", params);
    },
    goToLogin() {
      this.$router.push("/");
    },
    getUserProfile(user) {
      if (!user) {
        return null;
      }
      const attributes = user.attributes;
      const profile = {
        id: attributes.sub,
        username: user.username,
        email: attributes.email,
        full_name: attributes.name,
        phone_number: attributes.phone_number
          ? attributes.phone_number.split("+55").join("")
          : "",
        gender: attributes.gender,
        cpf: attributes["custom:cpf"],
        photo: attributes.picture,
      };

      return profile;
    },
    validateCPF(cpf) {
      var sum = 0;
      var rest;
      var strCPF = cpf.replace(/\./g, "").replace(/-/g, "");

      if (
        strCPF.length !== 11 ||
        !Array.from(strCPF).filter((e) => e !== strCPF[0]).length
      ) {
        return false;
      }

      for (let i = 1; i <= 9; i++) {
        sum += parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      }
      rest = (sum * 10) % 11;

      if (rest == 10 || rest == 11) rest = 0;
      if (rest != parseInt(strCPF.substring(9, 10))) return false;

      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum += parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      }
      rest = (sum * 10) % 11;

      if (rest == 10 || rest == 11) rest = 0;

      if (rest != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    cleanPhoneNumber(phone_number) {
      return `+55${phone_number
        .replace(/[^\w\s]/gi, "")
        .split(" ")
        .join("")}`;
    },
  },
});

export { postMessagesTypes, urlParams };
