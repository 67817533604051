import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import { usersState, usersMutations } from "./modules/users";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  state: {
    params: {},
    isGoogleFramedSignIn: false,
    ...usersState,
  },
  mutations: {
    SET_PARAMS(state, payload) {
      state.params = payload;
    },
    SET_GOOGLE_FRAMED_SIGN_IN(state, payload) {
      state.isGoogleFramedSignIn = payload;
    },
    ...usersMutations,
  },
  actions: {},
  modules: {},
});
