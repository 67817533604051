import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Toast, { POSITION } from "vue-toastification";
import { VueMaskDirective } from "v-mask";
import request from "./utils/request";

import "./plugins/amplify";
import "./utils/helpers";
import "./utils/local-storage-mixin";

import "vue-toastification/dist/index.css";

Vue.config.productionTip = false;
Vue.prototype.$http = request;

Vue.use(Toast, { position: POSITION.TOP_RIGHT, timeout: 3000 });
Vue.directive("mask", VueMaskDirective);

new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
