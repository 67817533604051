import Vue from "vue";
import ls from "local-storage";

Vue.mixin({
  methods: {
    ls_setGoogleSuccess(value) {
      ls.set("googleSuccess", value);
    },
    ls_getGoogleSuccess() {
      return ls.get("googleSuccess");
    },
    ls_attachListener(callback) {
      ls.on("googleSuccess", callback);
    },
  },
});
