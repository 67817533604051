import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Sign_in",
    component: () => import("../views/Sign_in.vue"),
  },
  {
    path: "/cadastro",
    name: "Sign_up",
    component: () => import("../views/Sign_up.vue"),
  },
  {
    path: "/recuperar-senha",
    name: "RecoverPassword",
    component: () => import("../views/RecoverPassword.vue"),
  },
  {
    path: "/perfil",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
