import axios from "axios";

const api = axios.create({
  baseURL: "https://qy5l8g1vc2.execute-api.us-east-1.amazonaws.com/v1",
});

api.interceptors.request.use((config) => {
  const { access_token } = JSON.parse(
    localStorage.getItem(process.env.VUE_APP_LOCALSTORAGE) || "{}"
  );

  if (access_token) {
    config.headers.authorization = access_token;
  }
  return config;
});

export default api;
